// Object - page - admission
.Admission {
    .page-content-heading {
        margin-top: 0;
    }
    .page-unbar-title {
        margin-top: 90px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}

.admission-flow-content {
  margin-top: 40px;
  .box {
    max-width: 800px;
    margin: 0 auto 0;
    padding: 25px 0px 0;
    position: relative;
    & + .box {
      margin-top: 25px;
    }
    @include _mq-down(md) {
      padding: 25px 0px 0;
    }
    .inr {
      border: 1px solid $color-brand;
      line-height: 2;
      padding: 2.8em 6em 2em;
      font-size: 1.4rem;
      letter-spacing: 0.05em;
      @include _mq-down(md) {
        padding: 2.8em 2em 2em;
      }
    }
    .head {
      display: block;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background-color: $color-brand;
      padding: .5em 0;
      margin: auto;
      max-width: 300px;
      line-height: 2;
      letter-spacing: 0.1em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .arrow {
      display: block;
      margin: 25px auto 0;
      width: 35px;
    }
    &.wrap .arrow {
      padding-top: 17px;
    }
  }
}

.application-nav-list {
    display: flex;
    justify-content: center;
    //border-right: 1px solid #c8c8c8;
    //border-left: 1px solid #c8c8c8;
    margin-top: 40px;
    li {
        width: 25%;
        text-align: center;
        border-right: 1px solid #c8c8c8;
        &:nth-child(1) {
            border-left: 1px solid #c8c8c8;
        }
        a {
            display: block;
            padding: 1.0em 0;
            font-size: 1.4rem;
            line-height: 1;
            letter-spacing: 0.1em;
            @include _mq-up(md) {
                &:hover {
                    color: $color-brand;
                }
            }
        }
    }
    @include _mq-down(md) {
        width: auto;
        li {
            width: 25%;
            a {
                padding: 0.5em 10px;
                line-height: 1.3;
            }
        }
    }
    @include _mq-down(sm) {
        li {
            width: auto;
            a {
                font-size: 1.3rem;
            }
        }
    }
}

/*.admission-policy-content {
  .box {
    background: url(../img/common/bg_stripe_brand.png);
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    margin: 0 auto 8em;
  }
  .inner {
    background-color: #fff;
    padding: 3em 4em 3.6em;
  }
  .page-content-heading + .page-content-heading {
    margin-top: 50px;
  }
  p {
    text-align: center;
  }
  @include _mq-down(md) {
    .page-content-heading {
      margin-top: 0;
    }
  }
  @include _mq-down(sm) {
    .box {
      width: 100%;
    }
  }
}*/

.entrance-inner {
  margin-top: 30px;
}
.flow-inner {
  margin-top: 15px;
}

.entrance-table {
  width: 100%;
  tr {
    border-bottom: 1px solid $color-brand;
  }
  @include _mq-up(md) {
    &.ao-appli {
        th:nth-child(1) {
            width: 72%;
        }
      }
    th, td {
      width: 50%;
    }
    th + th {
      border-left: 1px solid #fff;
    }
    td:nth-last-child(1) {
      border-left: 1px solid $color-brand;
      text-align: center;
    }
  }
  th {
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: $color-brand;
    text-align: center;
    padding: 1.4em 1.6em;
    letter-spacing: 0.075em;
  }
  td {
    font-size: 1.3rem;
    line-height: 1.7;
    position: relative;
    padding: 1.4em 1.6em;
    letter-spacing: 0.05em;
    @include _mq-up(md) {
      .date {
        margin-top: -40px;
      }
    }
    .mt-spacer {
      margin-top: 1em;
    }
    .bg-gray {
      padding: .4em 0;
      text-align: center;
      background-color: #DCDCDC;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 20px;
      width: 90%;
    }
    .note {
      font-size: 90%;
      margin-top: 7px;
    }
  }
  @include _mq-down(md) {
    tr {
      width: 100%;
    }
    th {
      padding: 1.0em 1.6em;
      display: block;
    }
    td {
      padding: 1.4em 0.5em;
      display: block;
      .bg-gray {
        position: static;
        margin-top: 15px;
        width: 100%;
      }
    }
    td:nth-last-child(1) {
      text-align: center;
    }
  }
}

.entrance-date-table {
    width: 48%;
    margin: 30px auto 20px;
    dt, dd {
        border: 1px solid $color-brand;
        text-align: center;
    }
    dt {
        color: #fff;
        font-weight: 500;
        font-size: 1.4rem;
        background-color: $color-brand;
        padding: 1em;
        letter-spacing: 0.075em;
    }
    dd {
        color: $color-brand;
        font-size: 1.4rem;
        line-height: 1.7;
        position: relative;
        vertical-align: middle;
        padding: 1em;
        letter-spacing: 0.05em;
        & + dd {
            border-top: 0;
        }
        span:nth-child(1) {
            display: inline-block;
            width: 70px;
        }
        span:nth-child(2) {
            display: inline-block;
            width: 150px;
            text-align: left
        }
    }
    @include _mq-down(sm) {
        width: 80%;
    }
}

.admission-howtoapply-content {
    .send-address {
        display: inline-block;
        border-bottom: 1px solid #ccc;
        margin-bottom: 0.5em;
    }
    .tit {
        font-weight: bold;
        margin-top: 1em;
    }
    .howtoapply-table {
        td:nth-child(1) {
            width: 6.25em;
            vertical-align: top;
        }
    }
    .mt-spacer {
        margin-top: 1em;
    }
    .page-btn-column {
        margin-top: 40px;
    }
}

.admission-web-content {
    .tit {
        font-size: 15px;
        font-weight: 600;
        margin-top: 0.5em;
    }
    .c-list-dot.column {
        display: flex;
        @include _mq-up(sm) {
            li {
                padding-right: 10px;
            }
        }
        @include _mq-down(sm) {
            display: block;
        }
    }
    .mt-spacer {
        margin-top: 2em;
    }
}

.entrance-support-table {
    th, td {
        font-size: 1.2rem;
        line-height: 1.7;
        letter-spacing: 0.03em;
    }
    tr {
        border-bottom: 1px dotted #808080;
    }
    th {
        text-align: justify;
        padding: 8px 10px 8px 5px;
        color: $color-brand;
        font-weight: normal;
        text-align: justify;
        width: 140px;
    }
    td {
        width: auto;
        padding: 8px 5px 8px 10px;
    }
    @include _mq-down(sm) {
        tr {
            display: block;
            padding: 10px 0 10px 0;
        }
        th, td {
            display: block;
            padding: 0;
            width: 100%;
        }
    }
}

.entrance-under-arrow {
    display: block;
    margin: 35px auto 35px;
    width: 35px;
}
.cell-Justify {
    display: flex;
    justify-content: space-between;
}

/**
* 学納金について
**/
.admission-expense-content {
    .page-unbar-title .year {
        color: #888;
        font-size: 83.333%;
        border-left: 1px solid #888;
        padding-left: 10px;
        margin-left: 10px;
        font-weight: normal;
    }
    .c-list-square {
        margin-top: 20px;
        line-height: 1.8;
        span {
            font-weight: bold;
        }
        li::before {
            color: $color-brand;
        }
    }
    .other-list {
        font-size: 13px;
        line-height: 1.8;
        margin-top: 15px;
        letter-spacing: 0.05em;
        .tit {
            font-weight: bold;
        }
    }
    .icon-swipe-sp {
        margin: 15px auto 0;
    }
}


.expense-table {
    width: 100%;
    margin-top: 10px;
    th, td {
        border: 1px solid $color-brand;
        font-weight: normal;
    }
    th {
        vertical-align: middle;
        padding: 1em 1em;
        text-align: center;
        letter-spacing: 0.1em;
    }
    td {
        text-align: right;
        padding: 2em 1em;
        letter-spacing: 0.05em;
    }
    thead tr:nth-child(1) th:nth-last-child(1) {
        border-right: 1px solid $color-brand;
    }
    thead th {
        color: #fff;
        background-color: $color-brand;
        border: 1px solid #fff;
        &:nth-child(1) {
            border-left: 1px solid $color-brand;
        }
    }
    tbody th {
        background-color: #eee;
        border: 1px solid $color-brand;
    }
}
@include _mq-down(md) { //スマホ時スクロール
    .expense-table-cover {
        overflow-x: scroll;
    }
    .expense-table {
        width: 800px;
    }
}

.expense-flow-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 30px;
    .item {
        width: 21%;
        border: 1px solid $color-brand;
    }
    .head {
        color: #fff;
        font-weight: bold;
        font-size: 1.4rem;
        text-align: center;
        background-color: $color-brand;
        padding: 10px 0;
        letter-spacing: 0.1em;
    }
    .inr {
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.5;
        letter-spacing: 0.075em;
    }
    img {
      display: block;
      height: 21px;
      width: auto;
    }
    @include _mq-down(md) {
        display: block;
        .item {
            width: 100%;
        }
        img {
            transform: rotate(90deg);
            text-align: center;
            margin: 10px auto;
            height: 20px;
        }
    }
}

.admission-stop-box {
    max-width: 800px;
    margin: 0 auto;
    padding: 1.6em 6em;
    border: 1px solid $color-brand;
    margin-top: 30px;
    .c-text {
        text-align: center;
    }
    .b-brand {
        color: $color-brand;
        font-weight: bold;
    }
    .phone {
        padding-left: 1em;
    }
    @include _mq-down(sm) {
        padding: 1.6em 20px;
        margin-top: 20px;
        .phone {
            padding-left: 0;
            font-size: 16px;
        }
    }
}

.admission-support-box {
    text-align: center;
    background: url(../img/common/bg_stripe_gray.jpg);
    padding: 2em 1em;
    margin-top: 50px;
    p {
        font-size: 1.2rem;
        margin-bottom: 1.8em;
        line-height: 1.7;
        letter-spacing: 0.05em;
    }
    .page-btn-center {
        margin-top: 15px;
    }
}

.admission-support-content {
  .catch {
    color: $color-brand;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.05em;
    margin-top: 30px;
  }
  .grade {
    color: #888;
    font-size: 83.333%;
    border-left: 1px solid #888;
    padding-left: 10px;
    margin-left: 10px;
    font-weight: normal;
  }
}

.admission-schola-table {
    margin-top: 30px;
    width: 100%;
    > tbody {
        > tr {
            border-bottom: 1px solid $color-brand;
            > th, > td {
                padding: 15px 20px;
                line-height: 1.7;
                font-weight: normal;
            }
            > th {
                width: 200px;
                text-align: left;
                color: #fff;
                background-color: $color-brand;
                border-bottom: 1px solid #fff;
                letter-spacing: 0.1em;
                vertical-align: top;
            }
            > td {
                letter-spacing: 0.05em;
            }
        }
        > tr:first-child {
            border-top: 1px solid $color-brand;
        }
        > tr:last-child {
            > th {
                border-bottom: 0;
            }
        }
    }
    dl.exam {
        display: flex;
        dt {
            width: 30px;
        }
    }
    .train-table {
        width: auto;
        td {
            padding-right: 1em;
        }
        /*span {
            letter-spacing: -0.4em;
            padding-right: 0.4em;
        }*/
    }
    @include _mq-down(md) {
        > tbody {
            > tr {
                > th, > td {
                    display: block;
                    width: 100%;
                    padding: 10px 15px;
                }
            }
        }
        .train-table {
            width: 100%;
            td {
                display: block;
            }
            td + td {
                padding-left: 4.68em;
            }
        }
    }
}

.skillup-table {
    th {
        font-weight: normal;
        width: 70px;
    }
    .kakaku {
      width: 60px;
    }
    .menjo {
      position: relative;
      padding: 0 10px;
    }
    .menjo::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        background: url("../../assets/img/admission/right_bracket.svg") no-repeat;
        background-size: contain;
        width: 7px;
        height: 65px;
    }
    .menjo::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        background: url("../../assets/img/admission/left_bracket.svg") no-repeat;
        background-size: contain;
        width: 7px;
        height: 65px;
    }
}

/**
* 書類
**/
.admission-doc-youkou {
    //margin-bottom: 120px;
    figure {
        width: 225px;
        margin: 30px auto 0;
        border: 1px solid #ccc;
    }
    .page-btn-center {
        margin-top: 30px;
    }
}

.info-content {
    padding-top: 120px;
    @include _mq-down(md) {
        padding-top: 80px;
    }
}

/**
* ポリシー
**/
.Policy {
    .page-text-tit-02 {
        margin-top: 20px;
        @include _mq-down(md) {
            margin-top: 15px;
        }
    }
    .page-text-tit-02-under {
        text-align: center;
        margin-top: 5px;
        @include _mq-down(md) {
            text-align: justify;
            margin-top: 0px;
        }
    }
    &-img {
        margin-top: 25px;
    }
    .page-content-heading {
        margin-top: 90px;
        .tit {
            padding-left: 0.5em;
        }
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
}
.policy {
    margin-top: 30px;
    & + .policy {
        margin-top: 30px;
    }
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    &-box {
        width: 570px;
        margin-top: 30px;
    }
    &-tit {
        font-size: 23px;
        font-weight: bold;
        color: $color-brand;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        span {
            font-size: 34px;
            letter-spacing: 0.05em;
        }
    }
    &-txt {
        margin-top: 10px;
    }
    &-img {
        width: 280px;
        margin-left: 50px;
        display: block;
    }
    @include _mq-down(md) {
        & + .policy {
            margin-top: 50px;
        }
        .left, .right {
            float: none;
        }
        &-box {
            width: 100%;
        }
        &-img {
            width: 280px;
            margin: 20px auto 0;
        }
        &-txt {
            margin-top: 12px;
        }
        &-tit {
            font-size: 18px;
            span {
                font-size: 26px;
            }
        }
    }
}


