// Object - project - info

/**
* 学校情報
**/
.Info {

}

.info-content {
    & + .info-content {
        margin-top: 120px;
        @include _mq-down(md) {
            margin-top: 100px;
        }
    }
}

.info-conttent-overview {

}

.info-content-dlbtn {
    max-width: 620px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .dlbtn-box {
        width: 100%;
        background: linear-gradient(135deg,$color-brand 0,$color-brand-accent 100%);
        margin-top: 20px;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 60px;
            color: #fff;
            font-size: 14px;
            letter-spacing: 0.1em;
            text-align: center;
            line-height: 1.5;
            position: relative;
            padding: 12px 50px;
        }
    }
    @include _mq-up(md) {
        .dlbtn-box.half {
            width: 48.4%;
            &:nth-of-type(even) {
                margin-left: 3.2%;
            }
        }
    }

    .icon-pdf::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 20px;
        width: 17.5px;
        height: 20px;
        background: url(../img/common/icon_pdf_white.png) center center / contain no-repeat;
    }
    @include _mq-down(md) {
        .dlbtn-box {
            margin-top: 15px;
            a {
                font-size: 12px;
                padding: 12px 40px;
            }
        }
        .dlbtn-box.half {
            width: 100%;
        }
        .icon-pdf::after {
            right: 15px;
        }
    }
}


