// Object - project - other

/**
* このサイトについて
**/
.privacy-mid-tit {
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 8px;
    letter-spacing: 0.05em;
}

/**
* 1・2年生のみなさんへ
**/
.student-content {
    .page-btn-column {
        margin-top: 50px;
    }
}

/**
* オープンキャンパス
**/
.Opencampus {
    .page-btn-center {
        margin-top: 50px;
        @include _mq-down(md) {
            margin-top: 30px;
        }
    }
    .opencampus-content-top .page-content-heading {
        margin-top: 0;
    }
}

.opencampus-heading {
    @include _mq-down(md) {
        width: 85%;
        margin: 0 auto;
    }
}

.opencampus-content-top {
    padding-top: 70px;
    .card {
        padding-top: 60px;
        margin-top: -60px;
    }
    .card + .card {
        margin-top: 0px;
        .opencampus-date-list {
            border-top: 1px dotted #999;
            padding-top: 50px;
        }
    }
}

.opencampus-center-text {
    text-align: center;
    margin-top: 20px;
    @include _mq-down(sm) {
        text-align: left;
        margin-top: 30px;
    }
}

.opencampus-date-list {
    margin: 0 auto;
    font-size: 15px;
    line-height: 1.7;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0 0 0;
    li {
        padding: 0 10px;
        text-align: center;
    }
    @include _mq-down(md) {
        padding: 20px 0 0 0;
    }
}

.opencampus-content-special {
    margin-top: 50px;
    padding-top: 70px;
    @include _mq-down(md) {
        margin-top: 20px;
    }
}

.opencampus-content-go {
    margin-top: 50px;
    padding-top: 70px;
    .page-text-tit-02 {
        margin-top: 30px;
    }
    @include _mq-down(md) {
        margin-top: 0px;
    }
}

.opencampus-image {
    margin-top: 30px;
    @include _mq-down(md) {
        margin-top: 20px;
    }
    img {
        border: 1px solid #ccc;
    }
}

/**
* 施設・設備
**/
.Facility {
    .page-text-tit-02 {
        margin-top: 20px;
        @include _mq-down(md) {
            margin-top: 15px;
        }
    }
    .page-text-tit-02-under {
        text-align: center;
        margin-top: 5px;
        @include _mq-down(md) {
            text-align: justify;
            margin-top: 0px;
        }
    }
}
.facility-content {
    margin-top: 80px;
    @include _mq-down(md) {
        margin-top: 65px;
    }
    .image01 {
        display: block;
    }
    .image02 {
        display: block;
        margin-top: 100px;
        @include _mq-down(md) {
            margin-top: 75px;
        }
    }
}

.Sitemap, .Privacy, .Info, .Sitepolicy {
    .page-content-heading {
        margin-top: 0;
    }
}

.Privacy, .Sitepolicy {
    .page-unbar-title {
        margin-top: 70px;
        &:nth-of-type(1) {
            margin-top: 50px;
        }
        @include _mq-down(md) {
            margin-top: 50px;
        }
    }
}

.sitemap-conttent-list, .info-conttent-overview {
    margin-top: 30px;
    @include _mq-down(md) {
        margin-top: 20px;
    }
}
