// Object - page - feature

/**
* ギフトウエディング
**/
@include _mq-down(md) {
    body:not(#home) .page-Content-block.Wedding {
        padding-bottom: 0;
    }
}

.wedding-process {
    margin-top: 143px;
    background: $color-brand-pale;
    //padding: 69px 79px 67px;
    padding: 69px 70px 67px;
    width: 100%;
    @include _mq-down(md) {
        margin-top: 70px;
        padding: 35px 19px 22px;
    }

    &__list {
        display: flex;
        margin-left: -40px;
        flex-wrap: wrap;
        margin-top: 50px;
        .item {
            width: 50%;
            display: flex;
            padding-left: 40px;
            @include _mq-up(md) {
                &:nth-child(n+3) {
                    margin-top: 50px;
                }
            }
        }
        .image {
            width: 50%;
        }
        .text {
            width: 50%;
            margin-left: 20px;
            .step {
                color: $color-brand;
                width: 100%;
                height: 30px;
                background: #fff;
                text-align: center;
                font-size: 17px;
                font-weight: bold;
                padding: 7px 6px 6px;
                margin-bottom: 8px;
                letter-spacing: 0.1em;
            }
        }
        @include _mq-down(md) {
            margin-left: -40px;
            margin-top: 30px;
            .item {
                width: 100%;
                & + .item {
                    margin-top: 32px;
                }
            }
            .text {
                margin-left: 16px;
                .step {
                    font-size: 14px;
                    height: 23px;
                    padding: 5px;
                }
            }
        }
    }

}

.sec-wedding-spacer {
    margin-top: 100px;
    @include _mq-down(md) {
        margin-top: 55px;
    }
}
.fotter-wedding-spacer {
   @include _mq-down(md) {
        margin-top: 50px;
   }
}

/**
* ゴーツデンエッグ
**/
.egg-strength {
    width: 100%;
    margin-top: 143px;
    padding: 69px 70px 67px;
    background: #f4faff;
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        margin-top: 40px;
        .item {
            width: 50%;
            padding-left: 40px;
            &:nth-child(n+3) {
                margin-top: 40px;
            }
            .image {

            }
            .tit-column {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $color-brand;
                padding-bottom: 5px;
                margin-top: 10px;
                .num {
                    color: $color-brand;
                    font-size: 50px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1;
                    padding-top: 6px;
                    width: 35px;
                }
                .tit {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 25px;
                    margin-left: 10px;
                    letter-spacing: 0.1em;
                }
            }
            .text {
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.1em;
                margin-top: 12px;
            }
        }
    }
    @include _mq-down(md) {
        margin-top: 50px;
        padding: 40px 19px 35px;
        &__list {
            margin-top: 25px;
            .item {
                width: 100%;
                padding-left: 40px;
                &:nth-child(n+2) {
                    margin-top: 35px;
                }
                .image {

                }
                .tit-column {

                }
            }
        }
    }
}

.egg-practice {
    &__item {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        &.first {
            margin-top: 70px;
        }
    }
    &__image {
        width: 50%;
        padding-left: 40px;
    }
    &__text {
        width: 50%;
        padding-left: 40px;
        .tit {
            width: 150px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            letter-spacing: .1em;
            background: $color-brand;
        }
        .lead {
            font-size: 18px;
            font-weight: bold;
            color: $color-brand;
            line-height: 34px;
            margin-top: 10px;
            letter-spacing: .1em;
        }
        .txt {
            margin-top: 5px;
        }
    }
    @include _mq-down(md) {
        .subject-box-tit {
            margin-top: 50px;
        }
        &__item {
            flex-flow: column-reverse;
            &.first {
                margin-top: 35px;
            }
        }
        &__image {
            width: 100%;
            margin-top: 12px;
        }
        &__text {
            width: 100%;
            .tit {
                margin: 0 auto;
            }
            .lead {
                font-size: 15px;
                line-height: 25px;
                margin-top: 15px;
                text-align: center;
            }
            .txt {
                margin-top: 7px;
            }
        }
    }
}

.egg-under-triangle {
    margin: 43px auto 37px;
    display: block;
    .color {
        fill: #B6D8F0;
    }
    @include _mq-down(md) {
        width: 28px;
        height: 11px;
        margin: 35px auto 25px;
    }
}

/**
* 施設・設備
**/
@include _mq-down(md) {
    body:not(#home) .page-Content-block.Facility {
        padding-bottom: 70px;
    }
}
.facility-head {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 58px;
    .image {
        width: 320px;
    }
    .text {
        width: 537px;
        .page-text-tit-01 {
            text-align: left;
            padding-left: 0;
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-top: 25px;
        .image {
            width: 640px;
            max-width: 100%;
            margin: 45px auto 0;
        }
        .text {
            width: 100%;
            .page-text-tit-01 {
                text-align: center;
                padding-left: 0.1em;
            }
        }
    }
}

.band-tips {
    width: 284px;
    margin-right: auto;
    margin-left: auto;
    font-size: 20px;
    color: #fff;
    background: $color-brand;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 11px;
    @include _mq-down(md) {
        width: 206px;
        font-size: 14px;
        padding: 8px;
    }
}


.band-tips-02 {
    width: 320px;
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
    background: #b6d8f0;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 13px;
    @include _mq-down(sm) {
        width: 100%;
    }
}
.facility-tips-spacer {
    margin-top: 50px;
    margin-bottom: 50px;
    @include _mq-down(md) {
        margin-bottom: 30px;
    }
}

.Facility-block {
    width: 100%;
    background: $color-brand-pale;
    padding-top: 140px;
    padding-bottom: 130px;
    @include _mq-down(md) {
        padding-top: 70px;
        padding-bottom: 140px;
    }
}

.facility-hakusan-img {
    margin-top: 25px;
    @include _mq-down(md) {
        margin-top: 55px;
    }
}

.Traning-block {
    width: 100%;
    background: $color-brand-pale;
    padding-top: 140px;
    padding-bottom: 130px;
    @include _mq-down(md) {
        margin-top: 70px;
        padding-top: 70px;
        padding-bottom: 140pxpx;
    }
    @include _mq-down(sm) {
        .page-content-heading .page-text-tit-01 .sub {
            display: block;
        }
    }
}

/**
* 研修制度
**/
.training-flow {
    background: #fff;
    padding: 68px 65px 50px;
    margin-top: 90px;
    @include _mq-down(md) {
        padding: 35px;
        margin-top: 65px;
    }
    &__list {
        .item {
            text-align: center;
            .tit {
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.08em;
                line-height: (32 / 18);
            }
            .text {
                font-size: 16px;
                letter-spacing: 0.08em;
                line-height: (32 / 18);
            }
            @include _mq-down(md) {
                .tit {
                    font-size: 13px;
                    line-height: (25 / 13);
                }
                .text {
                    font-size: 12px;
                    line-height: (20 / 12);
                }
            }
        }
        svg.arrow {
            width: 18px;
            height: 7px;
            display: block;
            fill: $color-brand;
            margin: 12px auto;
            @include _mq-down(md) {
                margin: 8px auto;
            }
        }
    }
}

/**
* 国際ライセンス
**/

.license_midtit {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background: $color-brand;
    letter-spacing: 0.1em;
    line-height: (28/20);
    width: 100%;
    padding: 28px;
    margin-top: 90px;
    @include _mq-down(md) {
        padding: 20px 16px;
        font-size: 16px;
        line-height: (22/16);
    }
}

.license_contain {
    margin-top: 115px;
    background: $color-brand-pale;
    padding: 69px 70px 67px;
    width: 100%;
    @include _mq-down(md) {
        margin-top: 70px;
        padding: 35px 19px 45px;
    }
}

.license_star {
    &__list {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        .item {
            width: 282px;
            .lead {
                color: $color-brand;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0.1em;
                line-height: (26/20);
                @include _mq-up(sm) {
                    text-align: center;
                }
            }
            .c-text {
                @include _mq-up(sm) {
                    text-align: center;
                }
            }
        }
        @include _mq-down(md) {
            .item {
                .lead {
                    font-size: 16px;
                    line-height: (22/16);
                }
                .c-text {
                    line-height: (20/13);
                    margin-top: 4px;
                }
            }
        }
        @include _mq-down(sm) {
            display: block;
            .min-pict + .min-pict {
                margin-top: _vw(-3, 375);
            }
            .item.min-pict {
                .text {
                    top: 13px;
                }
            }
            .item {
                width: 100%;
                display: flex;
                img {
                    display: block;
                    width: 50%;
                    margin-left: _vw(-10, 375);
                }
                .text {
                    width: 50%;
                    position: relative;
                    top: 10px;
                    //margin-left: 7px;
                }
            }
        }
    }
}

.license-tit-spacer {
    margin-top: 35px;
}


