// Object - project - subject

/**
* 学科紹介
**/
.Subject {
    .page-content-area {
        padding-top: 60px;
        background-color: $color-brand-pale;
    }
    .pnav {

    }
}
@include _mq-down(md) {
    .subject-heading {
        display: flex;
        flex-direction: column-reverse;
    }
}

.Subject {
    position: relative;
    &::after {
        content: '';
        background-color: $color-brand-pale;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    .top-tit {
        font-size: 4.5rem;
        font-weight: bold;
        color: $color-brand;
        line-height: 1;
        letter-spacing: 0.05em;
        position: relative;
        padding-bottom: 27px;
        &::after {
            content: '';
            width: 70px;
            height: 2px;
            background: $color-brand;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        @include _mq-down(md) {
            font-size: 25px;
            line-height: 1.4;
            text-align: center;
            padding-bottom: 15px;
            &::after {
                width: 20px;
                height: 2px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
        }
    }
    .top-area {
        display: flex;
        position: relative;
        height: 413px;
        .photo {
            max-width: 630px;
            height: 413px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        @include _mq-down(md) {
            display: block;
            height: auto;
            .photo {
                max-width: 100%;
                height: auto;
            }
        }
        .text-box {
            width: 630px;
            min-width: 530px;
            padding: 60px 52px 40px;
            background: #eaf6fe; //固定色
            & .inr {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                @include _mq-up(md) {
                    &.selected {
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        margin-top: -12px;
                    }
                }
            }
            @include _mq-down(md) {
                width: 100%;
                min-width: auto;
                padding: 25px 30px 30px 30px;
            }
        }
        .ann-box {
            display: flex;
            margin-top: 28px;
            .item {
                font-size: 15px;
                text-align: center;
                width: 140px;
                line-height: 1;
                padding: 9px 5px;
                border: 1px solid #909090;
                background: #fff;
                letter-spacing: 0.05em;
                & + .item {
                    margin-left: 10px;
                }
            }
            @include _mq-down(md) {
                justify-content: center;
                margin-top: 20px;
                .item {
                    font-size: 14px;
                    padding: 8px 10px;
                }
            }
        }
        .course-selected {
            font-size: 25px;
            font-weight: bold;
            color: $color-brand;
            letter-spacing: 0.05em;
            margin-top: 30px;
            @include _mq-down(md) {
                font-size: 22px;
                text-align: center;
                margin-top: 25px;
            }
        }
        .band-tips {
            color: #fff;
            font-size: 15px;
            line-height: 1;
            letter-spacing: 0.1em;
            padding: 10px 5px;
            width: 350px;
            text-align: center;
            background: $color-brand;
            margin-bottom: 25px;
            margin-right: 0;
            margin-left: 0;
            @include _mq-down(md) {
                font-size: 14px;
                width: 100%;
                max-width: 320px;
                margin: 0 auto 15px;
                padding: 8px 5px;
            }
        }
    }
    .page-content-area {
        @include _mq-down(md) {
            padding-top: 50px;
        }
    }
    .page-btn-column {
        margin-top: 50px;
    }
    .page-btn-center {
        margin-top: 30px;
    }
    .page-content-heading {
        @include _mq-down(md) {
            margin-top: 0;
            .u-d-flex-center {
                display: block;
                .sub {
                    display: block;
                    line-height: 1;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

.subject-box-tit {
    font-size: 22px;
    font-weight: bold;
    border: 1px solid $color-brand;
    padding: 18px;
    width: 590px;
    margin: 140px auto 0;
    text-align: center;
    letter-spacing: 0.05em;
    background: #fff;
    position: relative;
    &::after {
        content: '';
        width: 1px;
        height: 27px;
        background: $color-brand;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: 0 auto;
    }
    @include _mq-down(md) {
        font-size: 16px;
        padding: 14px;
        margin: 70px auto 0;
        width: 335px;
        &::after {
            height: 15px;
            bottom: -8px;
        }
    }
    @include _mq-down(sm) {
        width: 100%;
    }
}

.subject-number-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 65px;
    margin-left: -45px;
    li {
        width: 50%;
        padding-left: 45px;
        .upper {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .number {
                font-size: 60px;
                color: $color-brand;
                width: 44px;
            }
            .tit {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.5;
                letter-spacing: 0.05em;
                position: relative;
                top: 1px;
            }
        }
        &:nth-child(n+3) {
            margin-top: 25px;
        }
    }
    @include _mq-down(md) {
        margin-top: 40px;
        li {
            width: 100%;
            .upper {
                margin-bottom: 5px;
                .number {
                    font-size: 45px;
                    color: $color-brand;
                    width: 34px;
                }
                .tit {
                    font-size: 15px;
                }
            }
            & + li {
                margin-top: 30px;
            }
            &:nth-child(n+3) {
                margin-top: 30px;
            }
        }

    }
}

.subject-content-top {
    padding-top: 110px;
    .image {
        width: 250px;
        margin: 40px auto 50px;
    }
    .graph {
        width: 100%;
    }
    @include _mq-down(md) {
        padding-top: 70px;
        .graph-cover {
            width: 100%;
            overflow-x: scroll;
        }
        .graph {
            width: 900px;
        }
    }
}

.subject-content-license {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 90px;
    }
}

.subject-select-box {
    background: #fff;
    border: 1px solid #c7c7c7;
    padding: 57px 16px 50px;
    margin-top: 130px;
    .lead {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-align: center;
        line-height: (38/25);
        .border-b-brand {
            padding-bottom: 1px;
            border-bottom: 3px solid $color-brand;
            letter-spacing: 0.1em;
        }
        .min {
            font-size: 85%;
        }
    }
    .page-btn-center {
        margin-top: 30px;
    }
    @include _mq-down(md) {
        margin-top: 90px;
        padding: 30px 16px 35px;
        .lead {
            font-size: 20px;
            line-height: (35/20);
            .border-b-brand {
                padding-bottom: 0px;
                border-bottom: 2px solid $color-brand;
            }
        }
        .page-btn-center {
            margin-top: 15px;
        }
    }
    @include _mq-down(sm) {
        .sp-mt {
            display: block;
            margin-top: -2px;
        }
    }
}

.subject-course-heading {
    background: $color-brand-pale;
    @include _mq-down(md) {
        padding-bottom: 60px;
    }
    .page-text-tit-01 {
        margin-top: 55px;
        @include _mq-down(md) {
            margin-top: 60px;
        }
    }
    .subject-conpile-wrap {
        margin-top: 55px;
        @include _mq-down(md) {
            margin-top: 35px;
        }
    }
}

.Course {
    .page-content-heading {
        margin-top: 140px;
        @include _mq-down(md) {
            margin-top: 70px;
        }
    }
    .l-wrap-inr {
        padding-bottom: 140px;
        @include _mq-down(md) {
            padding-bottom: 0px;
        }
    }
}


/**
* めざせる職種・就職実績
**/
.subject-rec-data-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    li {
        background: $color-brand-pale;
        width: 436px;
        height: 103px;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0.1em;
        }
        .num {
            font-size: 50px;
            color: $color-brand;
            letter-spacing: 0.05em;
            position: relative;
            top: 3px;
            padding-left: 4px;
        }
        .unit {
            font-size: 30px;
            color: $color-brand;
            letter-spacing: 0.05em;
            position: relative;
            top: 1px;
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-top: 30px;
        li {
            max-width: 436px;
            width: 100%;
            height: 50px;
            margin-right: auto;
            margin-left: auto;
            & + li {
                margin-top: 10px;
            }
            .text {
                font-size: 16px;
            }
            .num {
                font-size: 30px;
            }
            .unit {
                font-size: 16px;
            }
        }
    }
}
.subject-data-tit {
    margin-top: 60px;
    @include _mq-down(md) {
        margin-top: 60px;
    }
}

.subject-check-wrap {
    margin-top: 20px;
    .card {
        width: 100%;
        border: 2px solid $color-brand;
        padding: 26px 30px 20px;
        & + .card {
            margin-top: 16px;
        }
    }
    .upper {
        display: flex;
        align-items: center;
        .check {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.1em;
            text-align: center;
            background: $color-brand;
            width: 100px;
            padding: 6px;
        }
        .tit {
            font-size: 20px;
            font-weight: bold;
            color: $color-brand;
            letter-spacing: 0.1em;
            margin-left: 20px;
        }
    }
    .lower {
        margin-top: 12px;
    }
    @include _mq-down(md) {
        margin-top: 50px;
        .card {
            position: relative;
            padding: 38px 20px 20px;
            & + .card {
                margin-top: 55px;
            }
        }
        .upper {
            .check {
                font-size: 20px;
                width: 154px;
                padding: 10px 9px 8px;
                letter-spacing: 0.05em;
                position: absolute;
                top: -19px;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
            .tit {
                font-size: 16px;
                line-height: (26/16);
                text-align: center;
                margin-left: 0px;
                padding-left: 0.1em;
                width: 100%;
            }
        }
    }
}

.sec-curriculum-c {
    margin-top: 70px;
}
.sec-curriculum-s {
    margin-top: 140px;
    @include _mq-down(md) {
        margin-top: 70px;
    }
}
.sec-pickup {
    margin-top: 140px;
    @include _mq-down(md) {
        margin-top: 70px;
    }
}

.subject-conpile-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -45px;
    margin-top: 15px;
    .page-content-conpile {
        width: 50%;
        padding-left: 45px;
        &:nth-child(1) {
            margin-top: 45px;
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-left: 0;
        .page-content-conpile {
            width: 100%;
            padding-left: 0;
        }
    }
}

.subject-conpile-wrap {
    margin-top: 20px;
    @include _mq-down(md) {
        margin-top: 20px;
    }
}



.subject-content-admit {
    padding-top: 100px;
    .logo-admit {
        width: 95px;
        margin: 0 auto 23px;
    }
    .logo-icam {
        width: 146px;
        margin: 40px auto 23px;
    }
    @include _mq-down(md) {
        padding-top: 70px;
    }
}

.subject-content-curriculum {
    padding-top: 100px;
    .c-list-circle-white {
        .name {
            font-weight: 500;
        }
        .leader {
            letter-spacing: -0.4em;
            padding-right: 0.5em;
        }
        @include _mq-down(md) {
            .name {
                display: block;
            }
            .leader {
                display: none;
            }
        }
    }
    .grade {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 5px;
        letter-spacing: 0.1em;
    }
    .line-t-txt {
        margin-top: 45px;
        border-top: 1px solid #c8c8c8;
        padding-top: 15px;
    }
    .text-tit {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 2;
    }
    @include _mq-down(md) {
        padding-top: 70px;
        .line-t-txt {
            margin-top: 25px;
        }
        .text-tit {
            font-size: 14px;
        }
    }
    .mt-spacer {
        margin-top: 10px;
    }
    .mt-spacer-02 {
        margin-top: 20px;
    }
}

.subject-content-pickup {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 70px;
    }
    .pickup-img {
        width: 280px;
        margin: 30px auto 0;
        &.unlayer {
            width: 350px;
        }
    }
    .pickup-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -30px;
        li {
            margin-top: 50px;
            padding-left: 30px;
            &.col3 {
                width: 33.3333%;
            }
            &.col2 {
                width: 45%;
            }
        }
        .name {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.05em;
            padding-bottom: 13px;
            margin-bottom: 9px;
            border-bottom: 1px solid #aaa;
        }
        @include _mq-up(md) {
            &.beauty, &.unlayer {
                justify-content: center;
                width: 610px;
                margin: 0 auto;
                li {
                    width: 280px;
                    padding-left: 0px;
                }
                li:nth-child(even) {
                    margin-left: 50px;
                }
            }
        }
        @include _mq-down(md) {
            li {
                margin-top: 30px;
                &.col3 {
                    width: 100%;
                }
                &.col2 {
                    width: 100%;
                }
            }
            .name {
                font-size: 16px;
            }
        }

    }
}

.subject-content-linkbtn {
    padding-top: 100px;
    @include _mq-down(md) {
        padding-top: 70px;
    }
}

/**
* 専攻
**/

.subject-content-reason {
    padding-top: 135px;
    .upper {
        background: $color-brand;
        padding: 40px 40px 15px;
        position: relative;
        .reason {
            position: absolute;
            top: -19px;
            left: 38px;
            min-width: 150px;
            margin: 0 auto;
            padding: 9px 20px;
            border: 2px solid #e6140a;
            color: #e6140a;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            letter-spacing: .05em;
            background: #fff;
        }
        .lead {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: 1.5;
        }
    }
    .box {
        border: 10px solid $color-brand;
        padding: 25px 30px;
        position: relative;
        background: #fff;
        .inr {
            max-width: 570px;
            font-size: 13px;
            line-height: 1.9231;
            letter-spacing: 0.05em;
            .name {
                margin-top: 10px;
                @include _mq-up(md) {
                    &.mt-spacer-01 {
                        margin-top: 30px;
                    }
                    &.mt-spacer-02 {
                        margin-top: 50px;
                    }
                }
            }
            .image {
                width: 220px;
                @include _mq-up(md) {
                    position: absolute;
                    bottom: 30px;
                    right: 30px;
                }
            }
        }
    }
    @include _mq-down(md) {
        padding-top: 95px;
        .upper {
            padding: 28px 21px 15px;
            .reason {
                max-width: 300px;
                font-size: 16px;
                top: -19px;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 8px 10px;
            }
            .lead {
                font-size: 15px;
                text-align: center;
                line-height: 1.7333;
            }
        }
        .box {
            border: 5px solid $color-brand;
            padding: 25px 15px;
            .tit {
                font-size: 15px;
                text-align: left;
            }
            .inr {
                line-height: 1.6923;
                .name {
                    text-align: center;
                }
                .image {
                    margin: 18px auto 0;
                }
            }
        }
    }
}

/**
* ワーキングスタディ
**/

.subject-content-graph {
    .work-graph {
        display: block;
        margin-top: 30px;
        @include _mq-down(md) {
            width: 320px;
            margin: 30px auto 0;
        }
    }
}

.subject-content-graph {
    .work-graph-schedule-sp {
        width: 320px;
        margin: 30px auto 0;
        li {
            border: 1px solid $color-brand;
            font-size: 11px;
            text-align: center;
            height: 45px;
            display: flex;
            align-items: center;
            background: #fff;
            margin-top: 20px;
            padding-left: 35px;
            span {
                color: $color-brand;
                font-size: 15px;
                font-weight: bold;
                padding-right: 10px;
                padding-left: 10px;
                letter-spacing: 0.05em;
            }
            &.d-center {
                justify-content: center;
                padding-left: 0;
            }
            &.icon-Acc {
                position: relative;
                cursor: pointer;
                &::before {
                    content: '';
                    width: 1px;
                    height: 15px;
                    background: #000;
                    position: absolute;
                    right: 7px;
                    opacity: 1;
                    transition: .4s;
                    margin-right: 10px;
                }
                &::after {
                    content: '';
                    width: 15px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    right: 0;
                    margin-right: 10px;
                }
            }
        }
        li.Acc-trigger {
            &.Active {
                &.icon-Acc {
                    &::before {
                        transform: rotate(90deg);
                        opacity: 0;
                    }
                }
            }
        }
        .Acc-target {
            display: none;
            border: 1px solid $color-brand;
            border-top: 0;
            background: #fff;
        }
    }
}
